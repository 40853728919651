<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog_show"
      persistent
      max-width="1024px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">{{ lang[lang_iso].title }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form
              ref="form"
              v-model="valid"
              lazy-validation
            >
              <v-row>
                <!-- SABLONA -->
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-autocomplete
                    v-model="dataForm.nazev"
                    :items="subject_items"
                    :label="lang[lang_iso].name"
                    :rules="nazevRules"
                  ></v-autocomplete>
                </v-col>
                <!-- POPIS -->
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-textarea
                    v-model="dataForm.text"
                    label="Popis problému"
                    value=""
                    hint="Zde vložte váš popis problému"
                    clearable
                  ></v-textarea>
                </v-col>

                <!-- EMAIL v pripade slovakov -->
                <v-col
                  cols="12"
                  sm="12"
                  md="12"
                >
                  <v-text-field
                    v-if="!is_logged_in"
                    v-model="dataForm.from_email"
                    label="*Vaša emailová adresu"
                    :rules="from_emailRules"
                    clearable
                  >
                  </v-text-field>
                </v-col>

                <!-- KOPIA: email -->
                <v-container fluid>
                  <v-combobox
                    v-if="is_logged_in"
                    v-model="dataForm.cc"
                    :rules="[v => (dataForm.cc.length > 0 ? cc_validate === true : true) || 'Jedna z emailových adries má nesprávny formát!']"
                    :items="items"
                    :search-input.sync="search"
                    hide-selected
                    hint="Maximum je 5 e-mailů"
                    label="Můžete zadat e-mailové adresy pro kopie"
                    multiple
                    small-chips
                    deletable-chips
                    hide-no-data
                    clearable
                    allow-overflow
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                </v-container>
              </v-row>
              <!-- OBRAZOK -->
              <v-row>
                <v-file-input
                  v-model="attachments"
                  :placeholder="lang[lang_iso].attach_placeholder"
                  accept="image/*"
                  :label="lang[lang_iso].attach_label"
                  multiple
                  @change="addImage"
                ></v-file-input>
              </v-row>
              <v-row>
              </v-row>
            </v-form>
          </v-container>
          <small>{{ lang[lang_iso].hint }}</small>
        </v-card-text>
        <!-- TLACITKA -->
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeDialog()"
          >
          {{ lang[lang_iso].btn_close }}
          </v-btn>
          <v-btn
            color="primary"
            text
            :loading="btn_send_loading"
            :disabled="is_logged_in ? !cc_validate : !is_validate"
            @click="completedDialog()"
          >
          {{ lang[lang_iso].btn_send }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
/* eslint-disable-next-line object-curly-newline */
/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint prefer-destructuring: ["error", {VariableDeclarator: {object: true}}] */
/* eslint-disable no-plusplus */

import response from '@/mixins/response'
import validate from '@/mixins/validate'
import store from '../../store'

// import { _ } from '@/utils'

export default {
  mixins: [response, validate],
  props: {
    show: { type: Boolean, default: false },
    title: { type: String, default: 'Formulář první pomoci' },
  },
  data() {
    return {
      attachments: null,
      dialog_show: false,
      valid: true,
      default_dataForm: {
        nazev: '',
        text: '',
        attachments: [],
        cc: [],
        from_email: '',
      },
      dataForm: {
        nazev: '',
        text: '',
        attachments: [],
        cc: [],
        from_email: '',
      },
      items: [],
      subject_items: [],
      search: null,
      cc_validate: true,
      is_validate: false,
      btn_send_loading: false,
      nazevRules: [
        v => !!v || 'Problém je povinné pole!',
      ],
      from_emailRules: [
        v => !!v || 'Nie ste prihlásený uživateľ, musíte vyplniť váš email aby sme s vami mohli komunikovať.',
      ],
      is_logged_in: false,
      lang: {
        sk: {
          title: 'Formulár prvej pomoci',
          name: '*Zvoľte váš problém',
          attach_label: 'Priloženie príloh',
          attach_placeholder: 'Sem môžete vložiť prílohy',
          btn_send: 'ODOSLAŤ',
          btn_close: 'ZAVRIEŤ',
          hint: 'polia označené * je potrebné vyplniť',
        },
        cz: {
          title: 'Formulář první pomoci',
          name: '*Zvolte váš problém',
          attach_label: 'Přiložení příloh',
          attach_placeholder: 'Zde můžete přiložit přílohy',
          btn_send: 'ODESLAT',
          btn_close: 'ZAVŘÍT',
          hint: 'pole označené * je třeba vyplnit',
        },
      },
      lang_iso: 'cz',
    }
  },
  computed: {

  },
  watch: {
    show: {
      immediate: true,
      handler(val) {
        if (val === true) {
          this.dataForm = this.lh.cloneDeep(this.default_dataForm)
          this.btn_send_loading = false
        }
        this.dialog_show = val
      },
    },
    'dataForm.cc': {
      immediate: true,
      handler() {
        this.validate()
      },
      deep: true,
    },
    'dataForm.from_email': {
      immediate: true,
      handler() {
        this.validate()
      },
      deep: true,
    },
    'dataForm.nazev': {
      immediate: true,
      handler() {
        this.validate()
      },
      deep: true,
    },
  },
  created() {
    this.dataForm = this.lh.cloneDeep(this.default_dataForm)
    this.dialog_show = this.show

    this.is_logged_in = (store && store.getters && store.getters.isAuthenticated) || false

    this.subject_items = this.is_logged_in ? ['Všeobecný IT požadavek', 'Technický IT problém', 'Hire Me', 'Excel a jiné OLE', 'Diagnostické data počítače pro IT'] : ['Všeobecná IT požiadavka', 'Technický IT problém', 'Recrue', 'Excel a iné OLE']
    this.lang_iso = this.is_logged_in ? 'cz' : 'sk'
  },
  mounted() {

  },
  methods: {
    completedDialog() {
      this.btn_send_loading = true
      setTimeout(() => {
        this.$emit('completedSOS', this.lh.cloneDeep(this.dataForm))
        this.btn_send_loading = false
        this.closeDialog()
      }, 1000)
    },
    closeDialog() {
      if (this.$refs.form) {
        this.$refs.form.resetValidation()
        this.$refs.form.reset()
        this.dataForm = this.lh.cloneDeep(this.default_dataForm)
      }
      this.$emit('closeSOS')
    },
    addImage() {
      if (this.attachments) {
        for (let i = 0; i < this.attachments.length; i += 1) {
          const reader = new FileReader()
          reader.readAsDataURL(this.attachments[i])
          reader.onload = () => {
            this.dataForm.attachments.push(reader.result.toString())
          }
        }
      } else {
        console.log('attachments error')
      }
    },
    validate() {
      const nazev_validate = this.dataForm.nazev.length > 0
      if (this.is_logged_in) {
        let cc_validate = false
        if (this.dataForm && this.dataForm.cc) {
          if (this.dataForm.cc.length > 5) {
            this.$nextTick(() => this.dataForm.cc.pop())
          }
          cc_validate = true
          for (let index = 0; index < this.dataForm.cc.length; index++) {
            const item = this.dataForm.cc[index]
            if (!this.isValidateEmail(item)) {
              cc_validate = false
            }
          }
        }
        this.cc_validate = cc_validate && nazev_validate
      } else {
        let is_validate = false
        if (this.dataForm.from_email) {
          is_validate = this.isValidateEmail(this.dataForm.from_email)
        }
        this.is_validate = is_validate && nazev_validate
      }
    },
  },
}
</script>
